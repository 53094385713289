export default {
  colors: {
    text: "#595C5B",
    textReverse: "#FFF",
    textReverseAlt: "#CCC",
    background: "#FFF",
    backgroundReverse: "#595C5B",
    primary: "#FCD06B",
    secondary: "#05a",
    accent: "#609",
    muted: "#f2f2f2",
  },
  fonts: {
    body: `'futura-pt', system-ui, sans-serif`,
    heading: `'futura-pt', system-ui, sans-serif`,
    monospace: `Menlo, monospace`,
    condensed: `'futura-pt-condensed', system-ui, sans-serif`,
    light: `'futura-pt-light', sans-serif`, // Newly added font
  },
  fontSizes: [10, 12, 14, 16, 20, 24, 28, 42, 64, 72, 17, 21, 25],
  fontWeights: {
    light: 300,
    body: 400,
    heading: 900,
    bold: 700,
    bolder: 800,
  },
  lineHeights: {
    body: 1.167,
    heading: 0.8,
  },
  letterSpacings: {
    body: "normal",
    heading: "-0.02em",
    caps: "normal",
  },
  space: [0, 4, 8, 16, 32, 64, 128],
  sizes: [0, 4, 8, 16, 32, 64, 128],
  textStyles: {
    caps: {
      textTransform: "uppercase",
    },
  },
  buttons: {
    default: {
      color: "#595C5B",
      backgroundColor: "#FFF",
    },
    reverse: {
      color: "#FFF",
      backgroundColor: "#595C5B",
    },
    primary: {
      color: "#595C5B",
      backgroundColor: "#FCD06B",
    },
  },
}
