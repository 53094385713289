import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import addToMailchimp from "gatsby-plugin-mailchimp"
import uuidv4 from "uuid/v4"
import css from "@styled-system/css"

import Box from "../box"
import Flex from "../flex"
import { H6 } from "../headings"
import Text from "../text"

const Newsletter = ({ variant, invert }) => {
  const [processing, setProcessing] = useState(false)
  const [email, setEmail] = useState("")
  const [failureMsg, setFailureMsg] = useState("")
  const id = uuidv4()

  return (
    <StaticQuery
      query={graphql`
        query {
          contact: datoCmsContact {
            newsletterHeading
            newsletterConsent
          }
        }
      `}
      render={data => (
        <Box
          as="form"
          noValidate
          onSubmit={e => {
            setProcessing(true)
            if (!processing) {
              setFailureMsg("")
              e.preventDefault()
              if (!/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
                setFailureMsg(
                  "Your email was entered incorrectly. Please enter a valid email to stay up-to-date."
                )
                setProcessing(false)
              } else {
                addToMailchimp(email).then(data => {
                  if (data.result === "success") {
                    setProcessing(false)
                    setEmail("Thanks for signing up!")
                  } else {
                    setProcessing(false)
                    setFailureMsg(
                      "There was a problem submitting your email. You may already be subscribed. If this issue persists, please contact us directly."
                    )
                  }
                })
              }
            }
          }}
          maxWidth={416}
        >
          <H6
            as="label"
            htmlFor={id}
            mb={24}
            color={variant === "reverse" ? "text" : "textReverse"}
            css={css({ display: "block" })}
          >
            {data.contact.newsletterHeading}
          </H6>
          <Flex
            className={processing ? "processing" : ""}
            css={css({
              opacity: 1,
              transform: "scale(1)",
              "&.processing": {
                pointer: "busy",
                opacity: 0.7,
                transition: "all 500ms ease-in-out",
                transform: "scale(0.95)",
              },
            })}
          >
            <input
              id={id}
              placeholder={`Email Address`}
              type="email"
              value={email}
              onChange={event => {
                setEmail(event.target.value)
              }}
              css={css({
                flex: 1,
                minWidth: 0,
                m: 0,
                px: [3, 24],
                py: 3,
                border: variant === "reverse" ? "1px solid" : 0,
                borderColor: "primary",
                borderRadius: 0,
                fontFamily: "body",
                fontSize: 16,
                fontWeight: "body",
                lineHeight: "body",
                color: "text",
                bg: "background",
                appearance: "none",
                "&:focus": { outline: 0 },
                "::placeholder": {
                  color: "text",
                },
              })}
            />
            <button
              children={`Submit`}
              css={css({
                m: 0,
                px: [3, 4],
                py: 3,
                border: 0,
                borderRadius: 0,
                fontFamily: "body",
                fontSize: [1, 2],
                fontWeight: "bold",
                letterSpacing: "0.05em",
                textAlign: "center",
                textTransform: "uppercase",
                color: "text",
                bg: "primary",
                appearance: "none",
                cursor: "pointer",
                userSelect: "none",
                overflow: "hidden",
                "&:focus": { outline: 0 },
              })}
            />
          </Flex>
          {/* {success && (
          <Text mt={3} color="textReverse">
            You're signed up!
          </Text>
        )} */}
          {failureMsg && (
            <Text mt={2} color={invert ? "textReverse" : "text"}>
              {failureMsg}
            </Text>
          )}
          <Text
            mt={3}
            fontSize={0}
            color="textReverseAlt"
            css={css({ textTransform: "uppercase" })}
          >
            {data.contact.newsletterConsent}
          </Text>
        </Box>
      )}
    />
  )
}

export default Newsletter
