import React, { createContext, useContext, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

export const BranchContext = createContext()

export const BranchProvider = BranchProviderInner

function BranchProviderInner({ children }) {
  const { branches } = useStaticQuery(
    graphql`
      query {
        branches: allDatoCmsBranch {
          nodes {
            url
            name
            area
            address
            mondayHours
            tuesdayHours
            wednesdayHours
            thursdayHours
            fridayHours
            saturdayHours
            sundayHours
            coordinates {
              latitude
              longitude
            }
            staff {
              role
              name
              email
              phone
              showContactInfo
            }
          }
        }
      }
    `
  )
  const [branch, setBranch] = useState(undefined)

  useEffect(() => {
    var xhttp = new XMLHttpRequest()
    xhttp.onreadystatechange = function() {
      if (this.readyState == 4 && this.status == 200) {
        let response = JSON.parse(xhttp.responseText)
        let user = {
          latitude: response.latitude,
          longitude: response.longitude,
        }
        let smallestDiff = 1000000000
        let currentBranch = undefined
        branches.nodes.forEach(branch => {
          if (branch.coordinates) {
            let latDif = Math.abs(branch.coordinates.latitude - user.latitude)
            let longDif = Math.abs(
              branch.coordinates.longitude - user.longitude
            )
            if (user.latitude < 0) {
              latDif = Math.abs(
                branch.coordinates.latitude + user.latitude * -1
              )
            }
            if (user.longitude < 0) {
              longDif = Math.abs(
                branch.coordinates.longitude + user.longitude * -1
              )
            }
            let diff = latDif + longDif
            if (diff < smallestDiff) {
              smallestDiff = diff
              currentBranch = branch
            }
          }
        })
        setBranch(currentBranch)
      }
    }
    xhttp.open(
      "GET",
      "//api.ipstack.com/check?access_key=db8e001f69d56610ba06b5987f136ecd",
      true
    )
    xhttp.send()
  }, [])

  return (
    <BranchContext.Provider
      value={{
        branch: branch,
        setBranch: setBranch,
      }}
    >
      {children}
    </BranchContext.Provider>
  )
}

export const useBranches = () => useContext(BranchContext)
