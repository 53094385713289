import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"
import Container from "../container"
import Lottie from "lottie-web"
import uuidv4 from "uuid/v4"

import { useContact } from "contexts/contact"

import { Flex, Box, H1, H6, Text, Button } from "components"

function Section({
  scrollAmount,
  image,
  imageFixed,
  video,
  videoStyle,
  cover,
  graphic,
  lottie,
  lottieStart,
  lottieStatic,
  lottieLoop,
  lottieCss,
  hero,
  title,
  subtitle,
  content,
  asterik,
  buttonText,
  buttonLink,
  buttonLinkType,
  buttonVariant,
  prompt,
  side,
  bg,
  color,
  height,
  contact,
  contactReason,
  top,
  buttonDisabled,
  id,
}) {
  let lottieRef = undefined
  let generatedId = uuidv4()

  const { setContactOpen, setReason } = useContact()
  const [lottieSet, setLottieSet] = useState(false)
  const [jogLottie, setJogLottie] = useState(false)

  useEffect(() => {
    return () => {
      if (lottieRef) {
        lottieRef.stop()
        lottieRef.destroy()
      }
    }
  }, [])

  useEffect(() => {
    if (
      ((lottie && scrollAmount > lottieStart) || (lottie && lottieStatic)) &&
      !lottieSet
    ) {
      setLottieSet(true)
      let documentDiv = document.getElementById(id + "lottie" + generatedId)
      let lottieContainer = Lottie.loadAnimation({
        container: documentDiv, // the dom element that will contain the animation
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: JSON.parse(JSON.stringify(lottie)), // the path to the animation json
      })
      setTimeout(
        () => {
          lottieContainer.goToAndPlay(0, true)
        },
        lottieStart <= 0 ? 800 : 0
      )
      setTimeout(() => {
        setJogLottie(true)
      }, 30)
      lottieContainer.onComplete = () => {
        if (lottieLoop !== undefined) {
          lottieContainer.goToAndPlay(lottieLoop, true)
        }
      }
      lottieRef = lottieContainer
    }
  }, [scrollAmount])

  if (image || title || subtitle || content) {
    return (
      <Flex
        bg={bg}
        position="relative"
        css={css({
          background:
            !video &&
            !imageFixed &&
            image &&
            (title
              ? "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), "
              : "") +
              "url(" +
              image +
              "?fit=crop&crop=center&max-w=1800&max-h=1200&auto=compress)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          overflow: "hidden",
          svg: {
            width: cover && "100% !important",
            height: cover && "90% !important",
          },
        })}
      >
        {imageFixed && (
          <Box
            css={css({
              background:
                !video &&
                image &&
                (title
                  ? "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), "
                  : "") +
                  "url(" +
                  image +
                  "?fit=crop&crop=center&max-w=1800&max-h=1200&auto=compress)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              zIndex: -1,
              position: "fixed",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
            })}
          />
        )}
        {video && (
          <div
            css={css({
              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15))",
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              zIndex: 0,
            })}
          >
            <video
              autoPlay="true"
              loop
              muted
              defaultMuted
              playsInline
              css={css({
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: -1,
              })}
              style={videoStyle}
              id={"sectionVideo" + id}
            >
              <source src={video} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
            <div
              css={css({
                background:
                  "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15))",
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
              })}
            />
          </div>
        )}
        <Container>
          <Flex
            flexDirection={["column", "column", "row"]}
            justifyContent={["flex-end", "flex-end", "center"]}
            alignItems={["flex-end", "flex-end", "center"]}
            minHeight={height ? height : "100vh"}
            pb={[5, 0]}
            pt={top && [0, top]}
            css={css({
              "@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)": {
                height: height ? height : "150vh",
              },
            })}
          >
            {cover && (
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                zIndex={-1}
              >
                {graphic}
              </Box>
            )}
            {side === "right" && (
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width={["100%", null, 1 / 2]}
                css={css({
                  "> *": {
                    width: ["75%", null, "initial"],
                    height: ["auto", null, "initial"],
                    pt: [5, null, 0],
                  },
                })}
              >
                {lottie && (
                  <div
                    id={id + "lottie" + generatedId}
                    css={css({
                      ...lottieCss,
                      " > svg": {
                        transform: jogLottie ? "initial !important" : "",
                      },
                    })}
                  />
                )}
                {!cover && <>{graphic}</>}
              </Flex>
            )}
            <Flex
              flexDirection="column"
              justifyContent="center"
              width={["100%", null, 1 / 2]}
              pt={[5, null, 0]}
              position="relative"
              zIndex={2}
            >
              {title && (
                <H1 as={!hero && "h2"} color={color}>
                  {title}
                </H1>
              )}
              {subtitle && (
                <Text
                  children={subtitle}
                  mt={[3, 4]}
                  fontFamily="light"
                  fontSize={[10, 11]}
                  color={color}
                />
              )}
              {content && (
                <Box mt={[3, 4]} css={css({ whiteSpace: "pre-line" })}>
                  {content}
                </Box>
              )}
              {asterik && (
                <Text
                  children={asterik}
                  mt={[3, 4]}
                  fontSize={1}
                  color={color}
                  css={css({ textTransform: "uppercase" })}
                />
              )}
              {buttonText && buttonLink && (
                <div>
                  <Button
                    children={buttonText}
                    disabled={buttonDisabled ? true : false}
                    as={buttonLinkType === "external" ? "a" : Link}
                    href={buttonLinkType === "external" ? buttonLink : ""}
                    target={buttonLinkType === "external" ? "_blank" : ""}
                    variant={buttonVariant}
                    rel={
                      buttonLinkType === "external" ? "noopener noreferrer" : ""
                    }
                    to={buttonLinkType === "external" ? "" : buttonLink}
                    mt={[3, 4]}
                  />
                </div>
              )}
              {contact && (
                <div>
                  <Button
                    children={buttonDisabled ? "Coming soon" : "Contact us"}
                    disabled={buttonDisabled ? true : false}
                    onClick={() => {
                      setContactOpen(true)
                      if (contactReason) {
                        setReason(contactReason)
                      }
                    }}
                    mt={[3, 4]}
                    variant={buttonVariant}
                  />
                </div>
              )}
            </Flex>
            {side !== "right" && !cover && (
              <Flex
                position="relative"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width={["75%", null, 1 / 2]}
                order={[-1, "initial"]}
                css={css({
                  "> *": {
                    width: ["100%", null, "initial"],
                    height: ["auto", null, "initial"],
                    pt: [5, null, 0],
                  },
                })}
              >
                {lottie && (
                  <div
                    id={id + "lottie" + generatedId}
                    css={css(lottieCss)}
                    // css={css({
                    //   marginLeft: ["-25%", "0"],
                    //   marginBottom: ["-25%", "0"],
                    //   width: ["100%", "80%"],
                    //   height: ["100%", "80%"],
                    // })}
                  />
                )}
                {graphic}
              </Flex>
            )}
            {prompt && (
              <Flex
                position="absolute"
                bottom="48px"
                left="50%"
                display={["none", "flex"]}
                flexDirection="column"
                alignItems="center"
                css={css({ transform: "translateX(-50%)" })}
              >
                <H6
                  children={prompt}
                  mb={2}
                  textAlign="center"
                  color="textReverse"
                />
                <svg
                  width={29}
                  height={10}
                  fill="none"
                  css={css({
                    verticalAlign: "top",
                  })}
                >
                  <path
                    d="M.25.43l14.12 8.15L28.48.43"
                    stroke="#fff"
                    strokeMiterlimit={10}
                  />
                </svg>
              </Flex>
            )}
          </Flex>
        </Container>
      </Flex>
    )
  } else {
    return null
  }
}

Section.defaultProps = {
  lottieStart: 0.25,
}

export default Section
