import React from "react"
import css from "@styled-system/css"
import * as MaterialIcon from "@mdi/react"

const Icon = ({ symbol, size, color, ...rest }) => (
  <MaterialIcon.Icon
    path={symbol}
    size={size / 1.5}
    css={css({
      verticalAlign: "top",
      color: color,
      fill: "currentColor",
    })}
    {...rest}
  />
)

Icon.defaultProps = {
  symbol: "",
  size: 1,
  color: "text",
}

export default Icon
