import React from "react"
import css from "@styled-system/css"

import { Box, Container, H3, Text } from "components"

const CopyBlock = ({ title, text }) => (
  <Box
    position="relative"
    css={css({
      "@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)": {
        "&:nth-of-type(1)": {
          msGridColumn: 1,
        },
        "&:nth-of-type(2)": {
          msGridColumn: 2,
        },
        "&:nth-of-type(3)": {
          msGridColumn: 3,
        },
      },
    })}
  >
    {title && <H3 children={title} />}
    {text && <Text children={text} mt={[3, 4]} lineHeight={1.5} />}
  </Box>
)

const CopySection = ({ section1, section2, section3 }) => (
  <Box as="section" pb={[5, 6]}>
    <Container>
      <Box
        css={css({
          display: "grid",
          gridTemplateColumns: ["1fr", "1fr", "1fr 1fr 1fr"],
          gridTemplateRows: ["1fr 1fr 1fr", "1fr 1fr 1fr", "1fr"],
          gridGap: "32px",
          "@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)": {
            display: "-ms-grid",
            msGridColumns: "1fr 32px 1fr 32px 1fr",
            msGridRows: "1fr",
            height: 600,
          },
        })}
      >
        {section1 && <CopyBlock {...section1} />}
        {section2 && <CopyBlock {...section2} />}
        {section3 && <CopyBlock {...section3} />}
      </Box>
    </Container>
  </Box>
)

export default CopySection
