import React from "react"
import { motion } from "framer-motion"

const duration = 1

const DunkText = ({ children, delay }) => {
  return (
    <>
      <motion.div
        variants={{
          initial: {
            opacity: 0,
            y: 30,
          },
          enter: {
            y: 0,
            opacity: 1,
            transition: {
              duration: duration,
              delay: delay ? delay : 1,
              when: "beforeChildren",
            },
          },
          exit: {
            y: 30,
            opacity: 0,
            transition: { duration: duration },
          },
        }}
        initial="initial"
        animate="enter"
        exit="exit"
      >
        {children}
      </motion.div>
    </>
  )
}

export default DunkText
