import styled from "@emotion/styled"
import shouldForwardProp from "@styled-system/should-forward-prop"
import { color, compose, flexbox, layout, position, space, width } from "styled-system"

const Flex = styled("div", {
  shouldForwardProp,
})(
  {
    minWidth: 0,
  },
  compose(
    color,
    flexbox,
    layout,
    position,
    space
  )
)

Flex.defaultProps = { display: "flex" }

export default Flex
