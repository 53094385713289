import React from "react"
import css from "@styled-system/css"

import Box from "../box"

const Textarea = props => {
  return (
    <Box bg="muted">
      <textarea
        {...props}
        css={css({
          display: "block",
          width: "100%",
          m: 0,
          p: 3,
          border: 0,
          borderRadius: 0,
          fontFamily: "body",
          fontSize: "16px",
          fontWeight: "body",
          lineHeight: "body",
          color: "text",
          bg: "muted",
          ":focus": { outline: 0 },
          "::placeholder": {
            color: "#999",
          },
        })}
      />
    </Box>
  )
}

export default Textarea
