import React, { createContext, useContext, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import ContactModal from "./components/contactModal"

export const ContactContext = createContext()

export const ContactProvider = ContactProviderInner

function ContactProviderInner({ children }) {
  const { contact } = useStaticQuery(
    graphql`
      query {
        contact: datoCmsContact {
          address
          contactFormTitle
          contactFormSubtitle
        }
      }
    `
  )
  const [contactOpen, setContactOpen] = useState(false)
  const [overrideBranch, setOverrideBranch] = useState(undefined)
  const [reason, setReason] = useState("I would like technical specifications")
  const [product, setProduct] = useState("")

  useEffect(() => {
    if (!contactOpen) {
      setOverrideBranch(undefined)
    }
  }, [contactOpen])

  return (
    <ContactContext.Provider
      value={{
        contactOpen: contactOpen,
        setContactOpen: setContactOpen,
        setOverrideBranch: setOverrideBranch,
        setReason: setReason,
        setProduct: setProduct,
      }}
    >
      {children}
      <ContactModal
        title={contact.contactFormTitle}
        subtitle={contact.contactFormSubtitle}
        showDialog={contactOpen}
        overrideBranch={overrideBranch}
        setShowDialog={setContactOpen}
        reason={reason}
        setReason={setReason}
        product={product}
        setProduct={setProduct}
      />
    </ContactContext.Provider>
  )
}

export const useContact = () => useContext(ContactContext)
