import React from "react"
import { motion } from "framer-motion"
import Cookies from "js-cookie"
import css from "@styled-system/css"

import Button from "../button"

const acceptPolicy = () => {
  Cookies.set("acceptCookiePolicy", "true")
}

const revealDuration = 0.5

const CookieBanner = ({ buttonOnClick }) => {
  return (
    <motion.div
      variants={{
        initial: {
          opacity: 0,
        },
        enter: {
          opacity: 1,
          transition: {
            duration: revealDuration,
            delay: revealDuration * 2,
            when: "beforeChildren",
          },
        },
        exit: {
          opacity: 0,
          transition: { duration: revealDuration / 2 },
        },
      }}
      style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 9999 }}
      initial="initial"
      animate="enter"
      exit="exit"
    >
      <div
        css={css({
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexFlow: "row nowrap",
          width: "100%",
          background:
            "linear-gradient(173deg, rgba(82,81,89,1) 0%, rgba(41,40,46,1) 100%);",
          color: "white",
          position: "fixed",
          bottom: 0,
          padding: "16px 128px",
          "@media(max-width: 60em)": {
            padding: "16px 32px",
          },
          "@media(max-width: 40em)": {
            flexFlow: "column wrap",
            textAlign: "center",
          },
        })}
      >
        This website uses cookies to ensure you get the best experience on our
        website.{" "}
        <a
          href="/cookie-policy"
          css={css({
            display: "inline-block",
            color: "#8D8C94",
            margin: "0 80px 0 18px",
            "@media(max-width: 60em)": {
              marginRight: "24px",
            },
            "@media(max-width: 40em)": {
              margin: "18px 0 24px",
            },
          })}
        >
          Cookie Policies
        </a>{" "}
        <Button
          onClick={() => {
            acceptPolicy()
            buttonOnClick(false)
          }}
        >
          Accept Cookies
        </Button>
      </div>
    </motion.div>
  )
}

export default CookieBanner
