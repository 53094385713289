import React from "react"
import css from "@styled-system/css"
import { keyframes } from "@emotion/core"

import Box from "../box"

const LineIn = keyframes`
  0% {
    transform: scale3d(0,0,1);
  }
  50% {
    transform: scale3d(1,1,1);
  }
  100% {
    transform: scale3d(0,0,1);
  }
`

const LineCover = props => (
  <Box
    position="fixed"
    top={0}
    left={0}
    right={0}
    bottom={0}
    zIndex={1000}
    style={{ pointerEvents: "none" }}
  >
    <Box
      position="absolute"
      top={0}
      width={"100vw"}
      height={"100vh"}
      style={{
        background: `
            repeating-linear-gradient(
              -45deg,
              rgba(255, 255, 255, 0),
              rgba(255, 255, 255, 0) 10vmin,
              ${props.color} 10vmin,
              ${props.color} 20vmin
            )
          `,
      }}
      css={css({
        transformOrigin: "top right",
        animation: `${LineIn} 10s ease-in-out forwards`,
      })}
    />
    <Box
      position="absolute"
      top={0}
      width={"100vw"}
      height={"100vh"}
      style={{
        background: `
            repeating-linear-gradient(
              -45deg,
              rgba(255, 255, 255, 1),
              rgba(255, 255, 255, 1) 10vmin,
              rgba(255, 255, 255, 0) 10vmin,
              rgba(255, 255, 255, 0) 20vmin
            )
          `,
      }}
      css={css({
        transformOrigin: "top right",
        animation: `${LineIn} 10s ease-in-out forwards 2s`,
      })}
    />
  </Box>
)

LineCover.defaultProps = {
  color: "#595C5B",
}

export default LineCover
