import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { Global } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import css from "@styled-system/css"
import Cookies from "js-cookie"

import { ContactProvider } from "contexts/contact"
import { BranchProvider } from "contexts/branches"

import { Footer, Locator, Header, SEO } from "../components"
import CookieBanner from "../components/cookieBanner"

import theme from "./theme"

const duration = 0.5

const variants = {
  initial: {
    opacity: 0,
    y: 30,
  },
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    y: 30,
    opacity: 0,
    transition: { duration: duration },
  },
}

function Layout({ children, location }) {
  const [showCookieBanner, setShowCookieBanner] = useState(
    !Cookies.get("acceptCookiePolicy")
  )

  return (
    <StaticQuery
      query={graphql`
        {
          datoCmsSite {
            globalSeo {
              fallbackSeo {
                title
                description
                image {
                  url
                  height
                  width
                }
                twitterCard
              }
            }
          }
        }
      `}
      render={site => (
        <ThemeProvider theme={theme}>
          <BranchProvider>
            <ContactProvider>
              <>
                <SEO
                  description={
                    site.datoCmsSite.globalSeo.fallbackSeo.description
                  }
                  title={site.datoCmsSite.globalSeo.fallbackSeo.title}
                  image={
                    site.datoCmsSite.globalSeo.fallbackSeo.image &&
                    site.datoCmsSite.globalSeo.fallbackSeo.image.url
                  }
                  height={
                    site.datoCmsSite.globalSeo.fallbackSeo.image &&
                    site.datoCmsSite.globalSeo.fallbackSeo.image.height
                  }
                  width={
                    site.datoCmsSite.globalSeo.fallbackSeo.image &&
                    site.datoCmsSite.globalSeo.fallbackSeo.image.width
                  }
                  imageAlt="Falkbuilt website card"
                />
                <Helmet>
                  <meta
                    name="viewport"
                    content="initial-scale=1, viewport-fit=cover"
                  />
                  <link
                    rel="stylesheet"
                    href="https://use.typekit.net/hhk0dkj.css"
                  />
                </Helmet>
                <Global
                  styles={css({
                    "*": {
                      boxSizing: "border-box",
                    },
                    body: {
                      m: 0,
                      fontFamily: "body",
                      fontKerning: "normal",
                      color: "text",
                      bg: "background",
                    },
                    a: {
                      color: "inherit",
                    },
                  })}
                />
                <Header location={location} />
                <AnimatePresence>
                  <motion.main
                    key={location.pathname}
                    variants={variants}
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    id="main"
                  >
                    {children}
                  </motion.main>
                </AnimatePresence>
                {location.pathname.indexOf("/contact") === -1 && <Locator />}
                <Footer location={location} />

                {showCookieBanner && (
                  <CookieBanner buttonOnClick={setShowCookieBanner} />
                )}
              </>
            </ContactProvider>
          </BranchProvider>
        </ThemeProvider>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
