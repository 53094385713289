import React from "react"
import Img from "gatsby-image"
import css from "@styled-system/css"
import { motion, AnimatePresence } from "framer-motion"
import { chunk, sum } from "lodash"

import Box from "../box"

const Gallery = ({
  images,
  onClick,
  itemsPerRow: itemsPerRowByBreakpoints,
}) => {
  const aspectRatios = images.map(image => image.aspectRatio)

  // For each breakpoint, calculate the aspect ratio sum of each row's images
  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
    itemsPerRow =>
      // Split images into groups of the given size
      chunk(aspectRatios, itemsPerRow).map(rowAspectRatios =>
        // Sum aspect ratios of images in the given row
        sum(rowAspectRatios)
      )
  )

  return (
    <Box m={[-1, -2]}>
      <AnimatePresence>
        {images.map((image, i) => (
          <Box
            width={rowAspectRatioSumsByBreakpoints.map(
              // Return a value for each breakpoint
              (rowAspectRatioSums, j) => {
                // Find out which row the image is in and get its aspect ratio sum
                const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j])
                const rowAspectRatioSum = rowAspectRatioSums[rowIndex]

                return `${(image.aspectRatio / rowAspectRatioSum) * 100}%`
              }
            )}
            maxWidth={"60%"}
            css={css({
              display: "inline-block",
              verticalAlign: "top",
              cursor: "pointer",
            })}
            onClick={() => {
              onClick(image)
            }}
            key={image.id}
          >
            <motion.div
              variants={{
                initial: {
                  opacity: 0,
                },
                enter: {
                  opacity: 1,
                  transition: {
                    duration: 0.6,
                    delay: i * 0.2,
                  },
                },
                exit: {
                  opacity: 0,
                  transition: { duration: 0.6 },
                },
              }}
              initial="initial"
              animate="enter"
              exit="exit"
            >
              <Img
                fluid={image.fluid}
                alt={image.alt}
                css={css({
                  img: {
                    p: [1, 2],
                  },
                })}
              />
            </motion.div>
          </Box>
        ))}
      </AnimatePresence>
    </Box>
  )
}

export default Gallery
