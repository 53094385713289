import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import css from "@styled-system/css"
import { mdiClose } from "@mdi/js"

import "@reach/dialog/styles.css"

import closeCursor from "../../../static/images/closeCursor.png"

import Box from "../box"
import Icon from "../icon"

const CloseButton = ({ ...rest }) => (
  <button
    title={`Close`}
    css={css({
      position: ["fixed", null, "absolute"],
      top: 0,
      right: 0,
      zIndex: 30,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: [4, null, 5],
      height: [4, null, 5],
      m: 0,
      p: 0,
      border: 0,
      bg: "background",
      appearance: "none",
      cursor: "pointer",
      "&:focus": { outline: 0 },
    })}
    {...rest}
  >
    <Icon
      symbol={mdiClose}
      color="text"
      css={css({ width: "24px !important", height: "24px !important" })}
    />
  </button>
)

export default ({ children, showDialog, setShowDialog }) => {
  return (
    <DialogOverlay
      isOpen={showDialog}
      onDismiss={() => setShowDialog(!showDialog)}
      css={css({
        zIndex: 10000,
        p: [0, null, 4, 5],
        bg: ["background", null, "rgba(5, 5, 5, 0.3)"],
        cursor: `url(${closeCursor}), auto`,
        WebkitOverflowScrolling: "touch",
      })}
    >
      <AnimatePresence>
        <motion.div
          variants={{
            initial: {
              opacity: 0,
            },
            enter: {
              opacity: 1,
              transition: {
                duration: 0.5,
                when: "beforeChildren",
              },
            },
            exit: {
              opacity: 0,
              transition: {
                when: "afterChildren",
              },
            },
          }}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          <DialogContent
            css={css({
              width: "100%",
              maxWidth: 1600,
              mx: "auto",
              my: 0,
              p: 0,
              bg: "transparent",
              cursor: "auto",
            })}
          >
            <Box
              width="100%"
              maxWidth={1600}
              minHeight={["100vh", null, 0]}
              mx="auto"
              p={[4, null, 5]}
              bg="background"
              css={css({
                position: "relative",
                boxShadow: "0 0 12px rgba(0, 0, 0, 0.25)",
              })}
            >
              <CloseButton onClick={() => setShowDialog(!showDialog)} />
              {children}
            </Box>
          </DialogContent>
        </motion.div>
      </AnimatePresence>
    </DialogOverlay>
  )
}
