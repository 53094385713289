import styled from "@emotion/styled"
import shouldForwardProp from "@styled-system/should-forward-prop"
import { color, compose, space, textStyle, typography } from "styled-system"

const Heading = styled("h1", {
  shouldForwardProp,
})(
  {
    margin: 0,
  },
  compose(
    color,
    space,
    textStyle,
    typography
  )
)

Heading.defaultProps = {
  fontFamily: "heading",
  fontWeight: "heading",
  lineHeight: "heading",
  letterSpacing: "heading",
  textStyle: "caps",
}

export const H1 = styled(Heading)``
H1.defaultProps = {
  ...Heading.defaultProps,
  as: "h1",
  fontSize: ["10vw", 9],
}

export const H2 = styled(Heading)``
H2.defaultProps = {
  ...Heading.defaultProps,
  as: "h2",
  fontSize: [6, 7],
  fontWeight: "900",
}

export const H3 = styled(Heading)``
H3.defaultProps = {
  ...Heading.defaultProps,
  as: "h3",
  fontSize: ["5vw", 5],
}

export const H6 = styled(Heading)``
H6.defaultProps = {
  ...Heading.defaultProps,
  as: "h6",
  fontSize: [2, 3],
  fontWeight: "700",
  lineHeight: "body",
  letterSpacing: "0.035em",
}
