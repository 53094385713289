import styled from "@emotion/styled"
import shouldForwardProp from "@styled-system/should-forward-prop"
import { color, compose, flexbox, layout, position, space } from "styled-system"

const Box = styled("div", {
  shouldForwardProp,
})(
  {
    minWidth: 0,
  },
  compose(
    color,
    flexbox,
    layout,
    position,
    space
  )
)

export default Box
