import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import css from "@styled-system/css"

import "@reach/dialog/styles.css"

import closeCursor from "../../../static/images/closeCursor.png"

import Box from "../box"

const CloseButton = ({ ...rest }) => (
  <button
    title={`Close`}
    css={css({
      position: ["fixed"],
      top: 0,
      left: 0,
      zIndex: 30,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "33%",
      height: [96, 120],
      m: 0,
      p: 0,
      border: 0,
      bg: "transparent",
      appearance: "none",
      cursor: "pointer",
      "&:focus": { outline: 0 },
    })}
    {...rest}
  >
    <svg width={40} height={40} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.914 20l9.293-9.293L29.5 10l-9.293 9.293L10.914 10l-.707.707L19.5 20 10 29.5l.707.707 9.5-9.5 9.5 9.5.707-.707-9.5-9.5z"
        fill="#fff"
      />
    </svg>
  </button>
)

export default ({ children, showDialog, setShowDialog }) => {
  return (
    <DialogOverlay
      isOpen={showDialog}
      onDismiss={() => setShowDialog(!showDialog)}
      css={css({
        zIndex: 10000,
        bg: "rgba(0, 0, 0, 0.4)",
        cursor: `url(${closeCursor}), auto`,
        WebkitOverflowScrolling: "touch",
      })}
    >
      <AnimatePresence>
        <motion.div
          variants={{
            initial: {
              scaleX: 0,
            },
            enter: {
              scaleX: 1,
              transition: {
                duration: 0.4,
                when: "beforeChildren",
              },
            },
            exit: {
              scaleX: 0,
              transition: {
                when: "afterChildren",
              },
            },
          }}
          initial="initial"
          animate="enter"
          exit="exit"
          style={{ transformOrigin: "right" }}
        >
          <DialogContent
            css={css({
              width: "67%",
              minHeight: "100vh",
              // maxWidth: [240],
              my: 0,
              p: 0,
              bg: "transparent",
              cursor: "auto",
              float: "right",
            })}
          >
            <Box
              width="100%"
              // maxWidth={400}
              minHeight={["100vh"]}
              p={4}
              bg="muted"
              css={css({
                position: "relative",
              })}
            >
              <motion.div
                variants={{
                  initial: {
                    opacity: 0,
                  },
                  enter: {
                    opacity: 1,
                    transition: {
                      duration: 0.2,
                    },
                  },
                  exit: {
                    opacity: 0,
                    transition: { duration: 0.2 },
                  },
                }}
              >
                <CloseButton onClick={() => setShowDialog(!showDialog)} />
                {children}
              </motion.div>
            </Box>
          </DialogContent>
        </motion.div>
      </AnimatePresence>
    </DialogOverlay>
  )
}
