import React from "react"
import { motion } from "framer-motion"
import css from "@styled-system/css"

const duration = 0.3

const DunkText = ({ lines }) => {
  return (
    <>
      {lines.map((line, index) => (
        <div css={css({ overflow: "hidden" })} key={index}>
          <motion.div
            variants={{
              initial: {
                y: 30,
                opacity: 0,
              },
              enter: {
                y: 0,
                opacity: 1,
                transition: {
                  duration: duration,
                  delay: 0.5 + index * 0.2,
                  when: "beforeChildren",
                },
              },
              exit: {
                y: 30,
                opacity: 0,
                transition: { duration: duration },
              },
            }}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            {line}
          </motion.div>
        </div>
      ))}
    </>
  )
}

export default DunkText
