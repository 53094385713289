import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"

import { Box, Flex, H2, Button, Text } from "components"

const Item = ({
  graphic,
  title,
  subtitle,
  buttonText,
  buttonLink,
  buttonVariant,
  color,
  bg,
}) => (
  <Box
    position="relative"
    bg={bg}
    css={css({
      "@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)": {
        "&:nth-of-type(1)": {
          msGridColumn: 1,
        },
        "&:nth-of-type(2)": {
          msGridColumn: 2,
        },
        "&:nth-of-type(3)": {
          msGridColumn: 3,
        },
      },
    })}
  >
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      p={[4, null, 5]}
    >
      {graphic && (
        <Box mb={4} css={css({ overflow: "visible" })}>
          {graphic}
        </Box>
      )}
      {title && <H2 children={title} textAlign="center" color={color} />}
      {subtitle && (
        <Text
          children={subtitle}
          mt={3}
          fontFamily="body"
          fontSize={[4, 5]}
          textAlign="center"
          color={color}
        />
      )}
      {buttonLink && buttonText && (
        <Button
          children={buttonText}
          as={Link}
          to={buttonLink}
          variant={buttonVariant}
          mt={4}
        />
      )}
    </Flex>
  </Box>
)

const SideBySide = ({ section1, section2, section3 }) => (
  <Box
    as="section"
    css={css({
      display: "grid",
      gridTemplateColumns: ["1fr", "1fr", "1fr 1fr 1fr"],
      gridTemplateRows: ["1fr 1fr 1fr", "1fr 1fr 1fr", "1fr"],
      "@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)": {
        display: "-ms-grid",
        msGridColumns: "1fr 1fr 1fr",
        msGridRows: "1fr",
        height: 600,
      },
    })}
  >
    {section1 && <Item {...section1} />}
    {section2 && <Item {...section2} />}
    {section3 && <Item {...section3} />}
  </Box>
)

export default SideBySide
