import React from "react"

import Box from "../box"

const Container = ({ children, maxWidth, secondBoxStyle, ...rest }) => (
  <Box width="100%" px={[4, null, 5, 6]} {...rest}>
    <Box width="100%" maxWidth={maxWidth} mx="auto" style={{ ...secondBoxStyle }}>
      {children}
    </Box>
  </Box>
)

Container.defaultProps = {
  maxWidth: 1600,
}

export default Container
