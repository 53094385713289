import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import css from "@styled-system/css"
import {
  mdiFacebook,
  mdiInstagram,
  mdiTwitter,
  mdiPinterest,
  mdiYoutube,
  mdiLinkedin,
} from "@mdi/js"

import Box from "../box"
import Container from "../container"
import logo from "../falk-logo-footer.png"
import Flex from "../flex"
import { H6 } from "../headings"
import Icon from "../icon"
import Newsletter from "../newsletter"
import Text from "../text"

const SocialButton = ({ href, icon, title }) => (
  <Flex
    as="a"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    title={title}
    justifyContent="center"
    alignItems="center"
    width={40}
    height={40}
    bg="background"
    css={css({ borderRadius: "50%" })}
  >
    <Icon
      symbol={icon}
      color="text"
      css={css({ width: "50% !important", height: "50% !important" })}
    />
  </Flex>
)

const FalkLogo = (props) => (
  <img src={logo} alt="Falk Logo" {...props} />
);

const Footer = ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          contact: datoCmsContact {
            address
            addressLink
            email
          }
          social: datoCmsSocial {
            instagram
            youtube
            linkedin
            pinterest
            facebook
            twitter
          }
        }
      `}
      render={data => (
        <Box as="footer" py={[5, 6]} bg="backgroundReverse">
          <Container as="section">
            <Link to="/">
              <FalkLogo
                css={css({
                  width: [(183 / 65) * 40, (183 / 65) * 64],
                  height: [40, 64],
                  color: "textReverse",
                })}
              />
            </Link>
          </Container>
          <Container as="section" mt={4} pb="env(safe-area-inset-bottom)">
            <Box
              css={css({
                display: "grid",
                gridTemplateColumns: ["1fr", null, "1fr 32px 1fr 64px 2fr"],
                gridTemplateRows: ["auto 64px auto 64px auto", null, "auto"],
                "@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)": {
                  display: "-ms-grid",
                  msGridColumns: "1fr 64px 1fr",
                  msGridRows: "auto",
                },
              })}
            >
              <Box
                css={css({
                  gridColumn: 1,
                  msGridColumn: 1,
                  gridRow: 1,
                  msGridRow: 1,
                })}
              >
                <address css={css({ fontStyle: "inherit" })}>
                  <Text color="textReverse">
                    <a
                      href={data.contact.addressLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      css={css({ textDecoration: "none", whiteSpace: "pre" })}
                      dangerouslySetInnerHTML={{ __html: data.contact.address }}
                    />
                  </Text>
                  <Text mt={4} color="textReverse">
                    <a
                      href={"mailto:" + data.contact.email}
                      css={css({ textDecoration: "none" })}
                    >
                      {data.contact.email}
                    </a>
                  </Text>
                </address>
              </Box>
              <Box
                css={css({
                  gridColumn: [1, null, 3],
                  msGridColumn: [1, null, 3],
                  gridRow: [3, null, 1],
                  msGridRow: [3, null, 1],
                  "> *:not(:last-child)": {
                    mb: 3,
                  },
                })}
              >
                <H6 color="textReverse">
                  <Link
                    children={`GALLERY`}
                    to="/vertical-markets/healthcare/"
                    css={css({ textDecoration: "none" })}
                  />
                </H6>
                <H6 color="textReverse">
                  <Link
                    children={`Who We Are`}
                    to="/about/"
                    css={css({ textDecoration: "none" })}
                  />
                </H6>
                <H6 color="textReverse">
                  <a
                    children={`Blog`}
                    href="/blog/"
                    css={css({ textDecoration: "none" })}
                  />
                </H6>
                <H6 color="textReverse">
                  <Link
                    children={`Contact`}
                    to="/contact/"
                    css={css({ textDecoration: "none" })}
                  />
                </H6>
                <H6 color="textReverse">
                  <a
                    children={`Careers`}
                    href="https://workforcenow.adp.com/jobs/apply/posting.html?client=falkerca&ccId=19000101_000001&type=MP&lang=en_CA"
                    target="_blank"
                    rel="noopener noreferrer"
                    css={css({ textDecoration: "none" })}
                  />
                </H6>
                {/* <H6 color="textReverse">
                  <Link
                    children={`Components`}
                    to="/components/"
                    css={css({ textDecoration: "none" })}
                  />
                </H6> */}
                <H6 color="textReverse">
                  <Link
                    children={`Privacy Policy`}
                    to="/privacy-policy/"
                    css={css({ textDecoration: "none" })}
                  />
                </H6>
                <H6 color="textReverse">
                  <Link
                    children={`Terms and Conditions`}
                    to="/terms-conditions/"
                    css={css({ textDecoration: "none" })}
                  />
                </H6>
                <H6 color="textReverse">
                  <Link
                    children={`Cookie Policy`}
                    to="/cookie-policy/"
                    css={css({ textDecoration: "none" })}
                  />
                </H6>
              </Box>
              <Box
                css={css({
                  gridColumn: [1, null, 5],
                  msGridColumn: [1, null, 5],
                  gridRow: [5, null, 1],
                  msGridRow: [5, null, 1],
                })}
              >
                <Newsletter invert={true} />
                <Flex
                  mt={4}
                  css={css({
                    "> *:not(:first-of-type)": {
                      ml: 2,
                    },
                  })}
                >
                  {data.social.linkedin && (
                    <SocialButton
                      icon={mdiLinkedin}
                      title={`LinkedIn`}
                      href={data.social.linkedin}
                    />
                  )}
                  {data.social.youtube && (
                    <SocialButton
                      icon={mdiYoutube}
                      title={`YouTube`}
                      href={data.social.youtube}
                    />
                  )}
                  {data.social.pinterest && (
                    <SocialButton
                      icon={mdiPinterest}
                      title={`Pinterest`}
                      href={data.social.pinterest}
                    />
                  )}
                  {data.social.instagram && (
                    <SocialButton
                      icon={mdiInstagram}
                      title={`Instagram`}
                      href={data.social.instagram}
                    />
                  )}
                  {data.social.facebook && (
                    <SocialButton
                      icon={mdiFacebook}
                      title={`Facebook`}
                      href={data.social.facebook}
                    />
                  )}
                  {data.social.twitter && (
                    <SocialButton
                      icon={mdiTwitter}
                      title={`Twitter`}
                      href={data.social.twitter}
                    />
                  )}
                </Flex>
                <Text
                  mt={3}
                  fontSize={0}
                  color="textReverseAlt"
                  css={css({ textTransform: "uppercase" })}
                >
                  © {new Date().getFullYear()} Falkbuilt.
                </Text>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    />
  )
}

export default Footer
