import styled from "@emotion/styled"
import css from "@styled-system/css"
import shouldForwardProp from "@styled-system/should-forward-prop"
import { buttonStyle, compose, space } from "styled-system"

const easing = "cubic-bezier(.215, .61, .355, 1)"
const timing = "200ms"

const Button = styled("button", {
  shouldForwardProp,
})(
  css({
    display: "inline-block",
    m: 0,
    px: [3, 4],
    py: 3,
    border: 0,
    borderRadius: 0,
    fontFamily: "body",
    fontSize: [1, 2],
    fontWeight: "bold",
    letterSpacing: "0.05em",
    textAlign: "center",
    textTransform: "uppercase",
    textDecoration: "none",
    color: "text",
    bg: "primary",
    appearance: "none",
    cursor: "pointer",
    userSelect: "none",
    transition: `background-color ${timing} ${easing}`,
    "&:focus": { outline: 0 },
    "&.active": {
      bg: "muted",
    },
    "&:disabled": {
      opacity: 0.8,
      cursor: "not-allowed",
    },
  }),
  compose(
    buttonStyle,
    space
  )
)

export default Button
