import React from "react"
import css from "@styled-system/css"

import { Box, Column, Container, Text } from "components"

import chevron from "./chevron.png"

const QuoteSection = ({ quote, name }) => (
  <Box as="section" py={[5, 6]}>
    <Box position="relative">
      <Box
        position="absolute"
        top={0}
        left={0}
        width={[0, null, 32, 64]}
        height="100%"
        css={css({
          backgroundImage: `url(${chevron})`,
          backgroundSize: "cover",
        })}
      />
      {quote && (
        <Container>
          <Column left>
            <Text
              children={quote}
              pt={[0, 5]}
              fontSize={["7.5vw", 7]}
              fontWeight="bold"
              color="primary"
            />
            <Text
              children={`- ${name}`}
              pt={[2, 4]}
              pb={[0, 5]}
              fontSize={["7.5vw", 7]}
              fontWeight="normal"
              color="primary"
            />
          </Column>
        </Container>
      )}
    </Box>
  </Box>
)

export default QuoteSection
