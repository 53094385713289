import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import css from "@styled-system/css"

import {
  Dialog,
  Flex,
  Column,
  H1,
  H2,
  H3,
  H6,
  Select,
  Button,
  Text,
  Box,
} from "components"

import { useBranches } from "contexts/branches"

const CustomInput = ({ error, ...props }) => {
  return (
    <Box position="relative">
      {error && (
        <Box
          position="absolute"
          top={0}
          height="100%"
          left={0}
          width="12px"
          bg="#D14C4C"
          css={css({ transform: "translateX(-100%)" })}
        />
      )}
      <input
        css={css({
          flex: 1,
          width: "100%",
          minWidth: 0,
          m: 0,
          py: "16px",
          px: "20px",
          border: 0,
          borderRadius: 0,
          fontFamily: "body",
          fontSize: "16px",
          fontWeight: "body",
          lineHeight: "body",
          color: error ? "#D14C4C" : "text",
          bg: "muted",
          appearance: "none",
          "&:focus": { outline: 0 },
          "::placeholder": {
            color: "#999",
          },
        })}
        {...props}
      />
    </Box>
  )
}

const CustomTextarea = ({ error, ...props }) => {
  return (
    <Box position="relative" bg="muted">
      {error && (
        <Box
          position="absolute"
          top={0}
          height="100%"
          left={0}
          width="12px"
          bg="#D14C4C"
          css={css({ transform: "translateX(-100%)" })}
        />
      )}
      <textarea
        {...props}
        css={css({
          display: "block",
          width: "100%",
          m: 0,
          p: 3,
          border: 0,
          borderRadius: 0,
          fontFamily: "body",
          fontSize: "16px",
          fontWeight: "body",
          lineHeight: "body",
          color: error ? "#D14C4C" : "text",
          bg: "muted",
          ":focus": { outline: 0 },
          "::placeholder": {
            color: "#999",
          },
        })}
      />
    </Box>
  )
}

function ContactModal({
  title,
  subtitle,
  reason,
  setReason,
  product,
  setProduct,
  setShowDialog,
  showDialog,
  overrideBranch,
}) {
  const { components } = useStaticQuery(
    graphql`
      query {
        components: datoCmsComponentsPage {
          partitions {
            name
          }
          doors {
            name
          }
        }
      }
    `
  )

  useEffect(() => {
    setNameError(false)
    setEmailError(false)
    setFailure(false)
    setSuccess(false)
  }, [showDialog])

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [failure, setFailure] = useState(false)

  const { branch } = useBranches()

  const encode = function (data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = function (e) {
    e.preventDefault()
    let error = false
    setNameError(false)
    setEmailError(false)
    if (!name) {
      error = true
      setNameError(true)
    }
    if (!email || !/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      error = true
      setEmailError(true)
    }
    if (!error) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name":
            "contact" +
            (overrideBranch ? overrideBranch.name : branch ? branch.name : ""),
          name: name,
          email: email,
          phone: phone,
          message: message,
          reason: reason,
          product: product,
        }),
      })
        .then(() => {
          setSuccess(true)
        })
        .catch(error => {
          setFailure(true)
        })
    }
  }

  return (
    <>
      <Dialog setShowDialog={setShowDialog} showDialog={showDialog}>
        <Column left>
          <H1 children={title} />
          <Text
            children={subtitle}
            mb={[4, 5]}
            mt={[3, 4]}
            fontFamily="body"
            fontSize={[4, 5]}
          />
        </Column>
        <Flex flexDirection={["column", null, "row"]} alignItems="flex-start">
          <Box width={["100%", null, 2 / 3]}>
            {!success && !failure && (
              <Box
                maxWidth={520}
                as={"form"}
                onSubmit={handleSubmit}
                name="contact"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="bot-field" />
                <H6 children={`Your Information`} mb={3} />
                <Box
                  css={css({
                    "> *:not(:last-child)": {
                      mb: [3, 4],
                    },
                  })}
                >
                  <Box>
                    <CustomInput
                      placeholder={`Full Name`}
                      name="name"
                      value={name}
                      onChange={event => {
                        setName(event.target.value)
                      }}
                      error={nameError}
                    />
                  </Box>
                  <Box>
                    <CustomInput
                      placeholder={`Email`}
                      name="email"
                      value={email}
                      onChange={event => {
                        setEmail(event.target.value)
                      }}
                      error={emailError}
                    />
                  </Box>
                  <Box>
                    <CustomInput
                      placeholder={`Phone Number (Optional)`}
                      name="phone"
                      value={phone}
                      onChange={event => {
                        setPhone(event.target.value)
                      }}
                    />
                  </Box>
                  <Box>
                    <H6 children={`Reason`} mb={3} />
                    <Select
                      value={reason}
                      name="reason"
                      onChange={event => {
                        setReason(event.target.value)
                      }}
                    >
                      <option
                        children={`I would like technical specifications`}
                      />
                      <option
                        children={`I would like to see your Revit Library`}
                      />
                      <option children={`General inquiry`} />
                      <option children={`Other`} />
                    </Select>
                  </Box>
                  <Box>
                    {reason === "I would like technical specifications" && (
                      <>
                        <H6 children={`Product`} mb={3} />

                        <Select
                          name="product"
                          value={product}
                          onChange={event => {
                            setProduct(event.target.value)
                          }}
                        >
                          <option disabled>Choose a product</option>

                          {components && components.doors && components.doors.length > 0 && components.doors.map(door => (
                            <option>Doors - {door.name}</option>
                          ))}

                          {components && components.partitions && components.partitions.length > 0 && components.partitions.map(partition => (
                            <option>Partitions - {partition.name}</option>
                          ))}
                        </Select>
                      </>
                    )}
                  </Box>
                  <Box>
                    <CustomTextarea
                      placeholder={"Your message"}
                      name="message"
                      rows="7"
                      value={message}
                      onChange={event => {
                        setMessage(event.target.value)
                      }}
                    />
                  </Box>
                </Box>
                <Button
                  children={`Submit`}
                  type={"Submit"}
                  variant="reverse"
                  mt={4}
                />
              </Box>
            )}
            {success && (
              <Box maxWidth={520}>
                <H2 children={`Thank you!`} />
                <Text
                  mt={3}
                  lineHeight={1.5}
                  children={`We will be in touch as soon as poosible. In the meantime why not check out some of the cool things Falk has to offer!`}
                />
                <Button
                  children={`Gallery`}
                  variant="reverse"
                  mt={4}
                  as={Link}
                  to={"/gallery/"}
                  onClick={() => {
                    setShowDialog(false)
                  }}
                />
              </Box>
            )}
            {failure && (
              <Box maxWidth={520}>
                <H2 children={`We had a problem!`} />
                <Text
                  mt={3}
                  lineHeight={1.5}
                  children={`Try refreshing this page and trying again, and if this issue persists, send us an email!`}
                />
                <Button
                  children={`Close`}
                  onClick={() => {
                    setShowDialog(false)
                  }}
                  variant="reverse"
                  mt={4}
                />
              </Box>
            )}
          </Box>
          {(branch || overrideBranch) && (
            <Box
              order={[-1, null, 1]}
              width={["100%", null, 1 / 3]}
              mb={[4, 5, 0]}
              ml={[0, null, 4]}
            >
              <Box p={4} bg="primary">
                <H2 mb={3}>
                  Your <br />
                  Branch
                </H2>
                <H3
                  children={overrideBranch ? overrideBranch.name : branch.name}
                  mb={3}
                />
                <Text
                  children={
                    overrideBranch ? overrideBranch.address : branch.address
                  }
                  mb={3}
                />
                <Text
                  children={
                    overrideBranch
                      ? overrideBranch.phone ||
                      (overrideBranch.staff &&
                        overrideBranch.staff[0] &&
                        overrideBranch.staff[0].phone)
                      : branch.phone ||
                      (branch.staff &&
                        branch.staff[0] &&
                        branch.staff[0].phone)
                  }
                />
                <Text
                  children={
                    overrideBranch
                      ? overrideBranch.email ||
                      (overrideBranch.staff &&
                        overrideBranch.staff[0] &&
                        overrideBranch.staff[0].email)
                      : branch.email ||
                      (branch.staff &&
                        branch.staff[0] &&
                        branch.staff[0].email)
                  }
                />
              </Box>
            </Box>
          )}
        </Flex>
      </Dialog>
    </>
  )
}

export default ContactModal
