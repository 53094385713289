import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"

import { Flex, H6, H2, Text, Box } from "components"

const PostCard = ({ width, image, date, title, subtitle, link }) => (
  <Flex
    width={width ? width : ["100%", null, 1 / 2, 1 / 3]}
    p={3}
    as={Link}
    to={link}
    css={css({ textDecoration: "none" })}
  >
    <Flex width="100%" flexDirection="column" p={4} bg="background">
      {image && (
        <Box
          css={css({
            backgroundImage:
              image &&
              'url("' +
                image +
                '?fit=crop&crop=center&max-w=800&max-h=800&auto=compress")',
            backgroundSize: "cover",
            backgroundPosition: "center",
          })}
          pb="67%"
        />
      )}
      <H2
        children={title}
        mt={image ? 4 : "auto"}
        css={css({
          fontSize: ["7.5vw", 6],
        })}
      />
      {date && (
        <Text
          children={date}
          mt={2}
          fontSize={2}
          css={css({ textTransform: "uppercase" })}
        />
      )}
      {subtitle && <Text children={subtitle} mt={3} />}
    </Flex>
  </Flex>
)

export default PostCard
