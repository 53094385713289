import React, { useState, useEffect, useContext } from "react"
import { Button, TabGroup } from "components"
import { ActiveFilterContext } from "../../contexts/project-filters"
import css from "@styled-system/css"

const ProjectFilter = ({ type, filterNodes }) => {
  const [filters, setFilters] = useState([{ label: "All", value: "All" }])
  const [isOpen, setIsOpen] = useState(false)
  const { activeFilters, setActiveFilters } = useContext(ActiveFilterContext)

  const toggleFilter = inputValue => {
    if (inputValue === "All") {
      setActiveFilters(prevState => ({
        ...prevState,
        [type]: [],
      }))
    } else {
      if (activeFilters[type].find(element => element === inputValue)) {
        setActiveFilters(prevState => ({
          ...prevState,
          [type]: activeFilters[type].filter(element => element !== inputValue),
        }))
      } else {
        setActiveFilters(prevState => ({
          ...prevState,
          [type]: [...prevState[type], inputValue],
        }))
      }
    }
  }

  useEffect(() => {
    const tabGroupFilters = filterNodes.map(type => {
      return {
        label: type.name,
        value: type.name,
      }
    })
    setFilters(existingArray => [...existingArray, ...tabGroupFilters])
  }, [])

  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >{`Filter`}</Button>
      {isOpen && (
        <TabGroup type={type} tabs={filters} toggleFilter={toggleFilter} />
      )}
    </>
  )
}

export default ProjectFilter
