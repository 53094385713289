import React from "react"
import css from "@styled-system/css"
import { triangle } from "polished"

const Select = ({ children, background, ...rest }) => (
  <div
    css={css({
      position: "relative",
      display: "inline-block",
      width: "100%",
    })}
  >
    <div
      css={css({
        position: "absolute",
        top: "50%",
        right: "16px",
        ...triangle({
          pointingDirection: "bottom",
          width: "10px",
          height: "5px",
          foregroundColor: "#999",
        }),
        transform: "translateY(-50%)",
        pointerEvents: "none",
      })}
    />
    <select
      css={css({
        display: "inline-block",
        width: "100%",
        m: 0,
        py: "16px",
        pr: "40px",
        pl: "20px",
        border: 0,
        borderRadius: 0,
        outline: 0,
        fontFamily: "body",
        fontSize: "16px",
        lineHeight: "body",
        color: "text",
        bg: "muted",
        cursor: "pointer",
        appearance: "none",
        ":focus": {
          outline: 0,
        },
        "::-ms-expand": {
          display: "none",
        },
        ":focus:-moz-focusring": {
          color: "transparent",
          textShadow: "0 0 0 #000",
        },
        background: background ? background : undefined
      })}
      {...rest}
    >
      {children}
    </select>
  </div>
)

export default Select
