import React, { createContext, useState } from "react"
const defaultState = {
  categories: [],
}

export const ActiveFilterContext = createContext(defaultState)
export const ActiveFilterProvider = props => {
  const [activeFilters, setActiveFilters] = useState(defaultState)
  return (
    <ActiveFilterContext.Provider
      value={{
        activeFilters,
        setActiveFilters,
      }}
    >
      {props.children}
    </ActiveFilterContext.Provider>
  )
}
