import React, { useState, useContext, useEffect } from "react"
import uuidv4 from "uuid/v4"
import css from "@styled-system/css"
import { hideVisually } from "polished"
import { ActiveFilterContext } from "../../contexts/project-filters"
import _ from "lodash"

const Button = ({ type, label, value, toggleFilter }) => {
  const id = uuidv4()
  const [isChecked, setIsChecked] = useState(false)
  const { activeFilters } = useContext(ActiveFilterContext)
  const handleOnChange = e => {
    toggleFilter(e.target.value)
  }

  useEffect(() => {
    if (_.isEmpty(activeFilters[type]) && value === "All") {
      setIsChecked(true)
    } else if (activeFilters[type].find(element => element === value)) {
      setIsChecked(true)
    } else {
      setIsChecked(false)
    }
  }, [activeFilters])

  return (
    <div css={css({ display: "inline-block" })}>
      <input
        id={id}
        name={id}
        value={value}
        checked={isChecked}
        onChange={handleOnChange}
        type="checkbox"
        css={css({
          ...hideVisually(),
          "&:checked + label": {
            borderColor: "transparent",
            color: "textReverse",
            bg: "backgroundReverse",
          },
        })}
      />
      <label
        children={label}
        htmlFor={id}
        css={css({
          position: "relative",
          display: "block",
          px: 3,
          py: 2,
          border: "1px solid",
          borderColor: "currentColor",
          fontFamily: "body",
          fontSize: [1, 2],
          fontWeight: "bold",
          letterSpacing: "0.05em",
          textAlign: "center",
          textTransform: "uppercase",
          color: "text",
          bg: "transparent",
          cursor: "pointer",
          userSelect: "none",
          transition: ".2s all",
        })}
      />
    </div>
  )
}

const TabGroup = ({ type, tabs, toggleFilter }) => {
  return (
    <div
      css={css({
        display: "block",
        maxWidth: ["95%"],
        mt: 4,
        mb: 4,
        ml: -2,
        "> *": {
          m: 2,
        },
      })}
    >
      {tabs.map(tab => (
        <Button
          type={type}
          label={tab.label}
          value={tab.value}
          key={tab.label + tab.value}
          toggleFilter={toggleFilter}
        />
      ))}
    </div>
  )
}

TabGroup.defaultProps = {
  tabs: [
    {
      label: "All",
      value: "all",
    },
  ],
}

export default TabGroup
