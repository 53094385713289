import React from "react"

import Box from "../box"
import Flex from "../flex"

const Column = ({ children, left, maxWidth, right, containerMaxWidth, containerMinWidth, ...rest }) => (
  <Flex maxWidth={containerMaxWidth} minWidth={containerMinWidth} {...rest}>
    <Box
      width="100%"
      maxWidth={maxWidth}
      ml={right && "auto"}
      mr={left && "auto"}
    >
      {children}
    </Box>
  </Flex>
)

Column.defaultProps = {
  maxWidth: 640,
}

export default Column
